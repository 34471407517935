.collapsible-outer {
    display: grid;
    grid-template-rows: 0fr;
    transition: 0.25s grid-template-rows ease-in-out;
    overflow: hidden;
    align-items: end;
}

.collapsible-outer.open {
    grid-template-rows: 1fr;
}

.collapsible-inner {
    overflow: hidden;
}