@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap");

#root {
  /* Color pallate */
  --green-1: hsl(74, 60%, 04%);
  --green-2: hsl(74, 60%, 14%);
  --green-3: hsl(74, 60%, 24%);
  --green-4: hsl(74, 60%, 34%); /* base */
  --green-5: hsl(74, 60%, 44%);
  --green-6: hsl(74, 60%, 54%);
  --green-7: hsl(74, 60%, 64%);
  --green-8: hsl(74, 60%, 74%);
  --green-9: hsl(74, 60%, 84%);
  --green-10: hsl(74, 60%, 94%);

  --red-1: hsl(2, 67%, 06%);
  --red-2: hsl(2, 67%, 16%);
  --red-3: hsl(2, 67%, 26%);
  --red-4: hsl(2, 67%, 36%);
  --red-5: hsl(2, 67%, 46%); /* base */
  --red-6: hsl(2, 67%, 56%);
  --red-7: hsl(2, 67%, 66%);
  --red-8: hsl(2, 67%, 76%);
  --red-9: hsl(2, 67%, 86%);
  --red-10: hsl(2, 67%, 96%);

  --black: hsl(0, 0%, 00%);
  --gray-1: hsl(0, 0%, 10%);
  --gray-2: hsl(0, 0%, 20%);
  --gray-3: hsl(0, 0%, 30%);
  --gray-4: hsl(0, 0%, 40%);
  --gray-5: hsl(0, 0%, 50%);
  --gray-6: hsl(0, 0%, 60%);
  --gray-7: hsl(0, 0%, 70%);
  --gray-8: hsl(0, 0%, 80%);
  --gray-9: hsl(0, 0%, 90%);
  --white: hsl(0, 0%, 100%);

  /* Color system */
  --light-text: white;
  --dark-text: rgb(81, 80, 80);
  --med-dark-text: var(--gray-5);
  --med-light-text: var(--gray-3);

  --color-accent: var(--green-4);
  --color-accent-light: var(--green-5);
  --color-accent-lighter: var(--green-6);
  --color-accent-lightest: var(--green-8);
  --color-accent-dark: var(--green-3);
  --color-accent-darker: var(--green-2);
  --color-accent-darkest: var(--green-1);

  --color-danger: var(--red-6);
  --color-danger-light: var(--red-7);
  --color-danger-lighter: var(--red-9);
  --color-danger-dark: var(--red-5);

  --color-disabled: var(--gray-5);
  --color-disabled-light: var(--gray-7);
  --color-disabled-dark: var(--gray-3);

  /* Sex Colors */
  --color-sex-female: hsl(341, 73%, 51%);
  --color-sex-male: hsl(192, 99%, 32%);
  --color-sex-undetermined: hsl(180, 1%, 62%);

  /* Shadow system */
  --shadow-distance-1: 3px;
  --shadow-distance-2: 6px;
  --shadow-distance-3: 9px;
  --shadow-1: 0 var(--shadow-distance-1) var(--shadow-distance-1) 0
    var(--gray-6);
  --filter-shadow-1: drop-shadow(
    0 var(--shadow-distance-1) var(--shadow-distance-1) var(--gray-6)
  );
  --shadow-2: 0 var(--shadow-distance-2) var(--shadow-distance-2) 0
    var(--gray-6);
  --filter-shadow-2: drop-shadow(
    0 var(--shadow-distance-2) var(--shadow-distance-2) var(--gray-6)
  );
  --shadow-3: 0 var(--shadow-distance-3) var(--shadow-distance-3) 0
    var(--gray-6);
  --filter-shadow-3: drop-shadow(
    0 var(--shadow-distance-3) var(--shadow-distance-3) var(--gray-6)
  );

  /* Old variables, probably remove later */
  --color-white: white;
  --color-text: var(--dark-text);
  --color-primary: #e0e8e8;
  --color-secondary-light: var(--color-accent-lightest);
  --color-secondary-dark: hsl(137, 3%, 46%);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Open Sans", sans-serif,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.body {
  width: 100%;
  background-color: var(--color-primary);
}

.App {
  display: grid;
  grid-template-rows: auto minmax(0, 1fr) auto;

  height: 100vh;
  height: 100dvh;
}

h1,
h2 {
  color: var(--color-text);
}

a {
  text-decoration: none;
  color: var(--color-accent);
}

a:hover:not(:disabled) {
  color: var(--color-white);
}

button {
  border: none;
  /* border-radius: 0.375em; */
  /* box-shadow: var(--shadow-1); */
  min-width: 7.75em;
  padding: 0.5em 1em;

  font: inherit;
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
}

button.false-enabled {
  --color-disabled: var(--color-accent-light);
  --color-disabled-light: var(--color-accent-lightest);
  --color-danger-lighter: var(--color-danger);
}

button.false-enabled.outlined {
  --color-disabled: var(--green-4);
}

button.false-enabled.danger {
  --color-disabled: var(--light-text);
}

button:where(.reset) {
  background-color: unset;
  box-shadow: unset;
  padding: unset;
  min-width: unset;
}

button.primary {
  background-color: var(--color-accent-light);
  color: var(--light-text);
}

button.primary:disabled {
  background-color: var(--color-disabled);
}

button.primary:hover:not(:disabled) {
  background-color: var(--color-accent-lighter);
}

button.secondary {
  background-color: var(--color-accent-lightest);
  color: var(--dark-text);
}

button.secondary:disabled {
  background-color: var(--color-disabled-light);
}

button.secondary:hover:not(:disabled) {
  background-color: var(--color-accent-lighter);
}

button.outlined,
button.danger.outlined {
  background-color: inherit;
  border: 1px solid var(--color-accent);
  color: var(--color-accent);
}

button.outlined:disabled {
  --color-accent: var(--color-disabled);
}

button.outlined:hover:not(:disabled) {
  background-color: var(--color-accent-lightest);
}

button.danger {
  background-color: var(--color-danger);
  color: var(--light-text);
}

button.danger:disabled {
  background-color: var(--color-danger-lighter);
  color: var(--color-disabled);
}

button.danger:hover:not(:disabled) {
  background-color: var(--color-danger-light);
}

button.danger.outlined {
  border-color: var(--color-danger);
  color: var(--color-danger);
}

button.danger.outlined:disabled {
  border-color: var(--color-danger-lighter);
  background-color: transparent;
  color: var(--color-danger-lighter);
}

button.danger.outlined:hover:not(:disabled) {
  background-color: var(--color-danger-lighter);
}

.person-icon {
  aspect-ratio: 1 / 1;
  border-radius: 100vmax;
}
